<template>
  <v-card>
    <v-card-title class="px-3 py-2" style="background-color: #3c8dbc">
      <h3 class="white--text" v-if="!post">{{ $t('infoboard.post.form.create_label') }}</h3>
      <h3 class="white--text" v-if="post">{{ $t('infoboard.post.form.edit_label') }}</h3>
      <v-spacer></v-spacer>
      <v-btn text icon color="grey lighten-5" @click="submit">
        <v-icon>mdi-content-save</v-icon>
      </v-btn>
      <v-btn text icon color="grey lighten-5" @click="$emit('close'); post ? reset() : ''">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-form>
      <v-row class="mx-0">
        <v-col md="8">
          <v-text-field :label="$t('infoboard.post.form.headline')" v-model="form.headline"
            :error-messages="errors.headline" />

          <the-dates _debug locale="de" type="date" _class="mx-2" width="320" label="Gültig von-bis" color="blue"
            header-class="blue lighten-1" clearable v-model="form.valid_date" _single></the-dates>

          <v-checkbox :label="$t('infoboard.post.form.important.label')" v-model="form.is_important"
            @click="showTooltip">
          </v-checkbox>
          <v-container>
            <v-row>
              <csm-file-input _debug :accept="$root.mimetypes" showUpload _disabled :reference="post ? post.id : 0"
                :url="`${$root.baseUrl.replace(/\/$/, '')}${$route.path}/posts`"
                :wait4trigger="post ? false : true"></csm-file-input>
            </v-row>
          </v-container>
          <v-textarea outlined v-model="form.text" label="Nachricht" height="400" :error-messages="errors.text">
          </v-textarea>
        </v-col>
        <v-col md="4">
          <v-treeview v-if="form" v-model="categories" :items="$root.hierarchies4info" open-all
            selection-type="independent" :active="activeTreeItems" activatable @update:active="getActiveValue">
            <template v-slot:prepend="{ item }">
              <v-icon v-if="item.children" v-text="`mdi-${item.id === 1 ? 'home-variant' : 'folder-network'}`"></v-icon>
            </template>
          </v-treeview>
          <v-divider></v-divider>
          <div>
            <p class="pr-3 text-end" v-if="errors.category_id" style="color:red">{{ errors.category_id[0] }}</p>
          </div>

        </v-col>
      </v-row>
    </v-form>
  </v-card>
</template>
<script>
import { EventBus } from 'csmed-elements/event-bus.js'
import CsmedDate from "csmed-elements/csm-date.vue"
import CsmFileInput from 'csmed-elements/csm-file-input.vue'
import TheDates from 'csmed-elements/the-dates'

export default {
  components: {
    CsmedDate,
    CsmFileInput,
    TheDates,
  },
  props: {
    post: Object,
    baseUrl: String,
    sessionId: String,
  },
  data: () => ({
    form: {
      category_id: 0,
    },
    activeTreeItems: [],
    categories: [],
    errors: {},
    openDialog: false,
  }),

  created() {
    if (this.post) {
      this.edit_mode()
    }

    this.activeTreeItems.push(this.form.category_id)
  },

  watch: {
    post: function (new_post_value, old_post_value) {
      if (new_post_value) {
        this.edit_mode()
      } else {
        this.reset()
      }
    }
  },

  methods: {

    showTooltip() {
      if (this.form.is_important) {
        this.$eventbus.$emit('infoboard_form_important_tooltip', { message: "Die Nachrichten werden den Benutzern solange als Popup beim Login angezeigt bis dieser Haken wieder entfernt wird." })
      }

    },

    getActiveValue(value) {
      //console.log(value)
      this.form.category_id = value[0]

    },


    reset() {
      this.form = {}
      this.errors = {}
    },

    edit_mode() {
      //csm-dates Typ wiederherstellen
      if (this.post.valid_from && this.post.valid_till) {
        this.post.valid_date = `${this.post.valid_from}:${this.post.valid_till}`
      }
      this.form = this.post
      this.form.category_id = this.post.category.id
    },

    category_selected(category_id) {
      this.form.category_id = category_id;
    },

    submit() {

      if (this.post) {
        this.edit()
      } else {
        this.create()
      }
    },

    create() {
      this.prepare_valid_date_for_request()

      this.$http({
        method: "post",
        url: `${this.$root.app_url}/infoboard/posts`,
        data: this.form,
      })
        .then((response) => {
          EventBus.$emit("fileUpload_submit", { reference: response.data })
          this.reset();
          this.$emit("created");
          this.$emit("close");
        })
        .catch((error) => {
          this.errors = error.response.data;
        })
        .finally(() => { });
    },

    edit() {
      this.prepare_valid_date_for_request()

      this.$http({
        method: "put",
        url: `${this.$root.app_url}/infoboard/posts/${this.post.id}`,
        data: this.form,
      })
        .then((response) => {
          this.reset();
          this.$emit("edited");
          this.$emit("close");
        })
        .catch((error) => {
          this.errors = error.response.data;
        })
        .finally(() => { });
    },

    prepare_valid_date_for_request() {
      if (this.form.valid_date) {
        this.form.valid_from = (this.form.valid_date.substring(0, this.form.valid_date.search(":")))
        this.form.valid_till = (this.form.valid_date.substring(this.form.valid_date.search(":") + 1))
      }
    }
  },
};
</script>