<template>

  <div id="infoboard"
       style="background-color: #fff;">

    <success-flash :message="$t('infoboard.post.created_success')"
                   :show="flash_message.show_created"
                   @close="flash_message.show_created = false" />
    <warning-flash :message="$t('infoboard.post.deleted')"
                   :show="flash_message.show_deleted"
                   @close="flash_message.show_deleted = false" />
    <error-flash :message="$t('infoboard.permission_missing')"
                 :show="!$root.user.permissions.infoboard"
                 :timeout="-1" />
    <csm-data-grid _debug
                   control-color="blue lighten-4"
                   header-color="blue lighten-1"
                   event-name-prefix="infoboardGrid_"
                   parent-id="infoboard"
                   param-store-name="infoboardStore"
                   filter-name="filter"
                   restore-check-session
                   restore-expires="86400"
                   :session-id="sessionId"
                   ref="grid"
                   :url="`${baseUrl.replace(/\/$/, '')}${$route.path}/posts`"
                   :start-params="startParams"
                   :rows-per-page-items="rowsPerPageItems"
                   rows-per-page-label="Zeilen/Seite"
                   :headers="headers"
                   title="Infoboard"
                   new-button
                   success-message="Aktion erfolgreich..."
                   generic-search="*3*"
                   generic-search-label="Suchen"
                   generic-search-hint="in Nachrichten"
                   more-input-hint="Bitte mehr Zeichen, Minimum: "
                   default-per="10"
                   left-width="20rem"
                   cards-below="1000">

      <template v-slot:item="props">
        <tr :class="!props.item.read ? '' : 'grey--text'">
          <td style="width:5%;"
              class="text-xs-left"><v-icon color="red"
                    v-if="props.item.is_important">mdi-alert-box</v-icon></td>
          <td style="width:5%;"
              @click="select_post(props.item)"
              class="text-xs-left">{{ props.item.created_at }}</td>
          <td style="width:10%;"
              @click="select_post(props.item)"
              class="text-xs-left">{{ props.item.created_by }}</td>
          <td _style="width:20%;"
              @click="select_post(props.item)"
              class="text-xs-left">

            <div><b :style="post_date_range_is_valid_for_today(props.item) ? '' : 'text-decoration: line-through;'">{{
              props.item.shortText }}</b></div>
            <div :style="post_date_range_is_valid_for_today(props.item) ? '' : 'text-decoration: line-through;'">{{
              props.item.text_shorten }}</div>
          </td>
          <td style="width:5%;"
              class="text-xs-left">
            <v-badge v-if="props.item.has_uploads"
                     inline
                     color="grey"
                     :content="props.item.has_uploads">
              <v-icon> mdi-paperclip</v-icon>
            </v-badge>
          </td>
          <td style="width:10%; text-align:right">

            <v-checkbox class="my-0 ml-2"
                        v-if="!props.item.read"
                        multiple
                        v-model="selected_posts_for_mass_edit"
                        :value="props.item.id"></v-checkbox>

          </td>
        </tr>
      </template>

      <template v-slot:no-data>
        <v-alert type="warning">
          <span class="red--text text--darken-4">keine Daten für diese Selektion...</span>
        </v-alert>
      </template>


      <template v-slot:left-filter
                class="mx-2">
        <v-layout row
                  wrap>
          <v-flex xs12>
            <v-card color="grey lighten-5"
                    class="ml-2">

              <v-treeview ref="tree"
                          :items="$root.hierarchies4info"
                          v-model="filter.depts_id"
                          selection-type="independent"
                          open-all
                          activatable
                          :active="activeTreeItems"
                          @update:active="getActiveValue">

                <template v-slot:prepend="{ item }">
                  <v-icon v-if="item.children"
                          v-text="`mdi-${item.id === 1 ? 'home-variant' : 'folder-network'}`"></v-icon>
                </template>
              </v-treeview>


            </v-card>
          </v-flex>
        </v-layout>
      </template>


      <template v-slot:main-filter>
        <v-card color="grey lighten-5">
          <v-card-actions class="px-2 py-0">
            <v-container fluid
                         _text-md-center>

              <v-row>
                <v-col sm="6"
                       lg="4">
                  <csm-switch3 rounded
                               class="pa-1"
                               _debug
                               v-model="filter.main.readYN"
                               label="gelesen?"
                               left-value="N"
                               middle-value=""
                               right-value="Y">
                  </csm-switch3>
                </v-col>

                <v-col sm="6">
                  <csm-switch3 rounded
                               class="pa-1"
                               _debug
                               v-model="filter.main.isImportant"
                               label="wichtige Nachrichten?"
                               left-value="0"
                               middle-value=""
                               right-value="1">
                  </csm-switch3>

                </v-col>
              </v-row>
            </v-container>


          </v-card-actions>
        </v-card>
      </template>

      <template v-slot:footer>
        <v-row class="ma-2">
          <v-btn @click="mark_all_as_read()"
                 class="primary ma-2">
            {{ $t("infoboard.post.mark_all_as_read") }}
          </v-btn>
          <v-spacer />
          <v-btn class="primary ma-2"
                 @click="mark_as_read()">{{ $t("infoboard.post.mark_as_read") }}</v-btn>
        </v-row>


      </template>

      <!--<template v-slot:expand-item="slotprops" >
          <tr>
            <td colspan="5" class="pa-1">
              <v-card flat color="yellow lighten-4">
                <p>{{  slotprops.item.text }}</p>

              </v-card>
             
            </td>
          </tr>
           
          
        </template>-->

    </csm-data-grid>


    <v-dialog v-model="post.show"
              persistent
              scrollable
              :max-width="this.$root.global_modal_width">
      <post :key="rerender_post_component_key"
            :post_id="post.selected.id"
            :categories="categories"
            @close="
              post.show = false;
            rerender_post_component_key += 1;
            "
            @marked_as_read="emit('infoboardGrid_refresh')"
            @edited="emit('infoboardGrid_refresh')"
            @deleted="
              emit('infoboardGrid_refresh');
            flash_message.show_deleted = true;
            " />
    </v-dialog>
    <v-dialog v-model="show_create_form"
              persistent
              :max-width="this.$root.global_modal_width">
      <post-form :key="rerender_post_form_component_key"
                 :categories="categories"
                 @close="
                  show_create_form = false;
                rerender_post_form_component_key = +1;
                "
                 @created="
                  emit('infoboardGrid_refresh');
                flash_message.show_created = true;
                " />
    </v-dialog>
    <v-overlay :value="request_in_progress">
      <div class="text-center mt-4">
        <v-progress-circular :size="100"
                             :width="7"
                             color="blue darken-3"
                             indeterminate />
      </div>
    </v-overlay>
  </div>
</template>
<script>
import { EventBus } from 'csmed-elements/event-bus.js'
import PostForm from "components/infoboard/post/_form.vue"
import Post from "components/infoboard/post/main.vue"
import { csm_util } from "mixins/csm_util.js"
import SuccessFlash from "components/snackbar/success.vue"
import WarningFlash from "components/snackbar/warning.vue"
import ErrorFlash from "components/snackbar/error.vue"
import CsmDataGrid from 'csmed-elements/csm-data-grid'
import CsmTree from 'csmed-elements/csm-tree'
import CsmSwitch3 from 'csmed-elements/csm-switch3'


export default {
  name: "infoboard",
  components: {
    PostForm,
    Post,
    SuccessFlash,
    WarningFlash,
    ErrorFlash,
    CsmDataGrid,
    CsmTree,
    CsmSwitch3,
  },
  props: {
    debug: Boolean,
    rootUrl: String,
    baseUrl: String,
    sessionId: String,
    startParams: Object,
  },
  mixins: [csm_util],
  data: () => ({
    show_create_form: false,
    flash_message: {
      show_created: false,
      show_deleted: false,
    },
    post: {
      show: false,
      selected: {},
    },
    items: [{ "name": "Alle Infos", "id": 514, "state": { "checked": false, "disabled": false }, "fid": "INFO_AL", "seq": null, "valid": true, "domain": "INFO", "path": ["Alle Infos"], "children": [{ "name": "Außendienst", "id": 515, "active": true, "state": { "active": true, "disabled": false }, "fid": "INFO_AU", "seq": null, "valid": true, "domain": "INFO", "path": ["Alle Infos", "Außendienst"], "children": [{ "name": "Region A", "id": 519, "state": { "checked": false, "disabled": false }, "fid": "INFO_AUE", "seq": null, "valid": true, "domain": "INFO", "path": ["Alle Infos", "Außendienst", "Region A"] }, { "name": "Region B", "id": 520, "state": { "checked": false, "disabled": false }, "fid": "INFO_AUI", "seq": null, "valid": true, "domain": "INFO", "path": ["Alle Infos", "Außendienst", "Region B"] }] }, { "name": "Geschäftsführung", "id": 517, "state": { "checked": false, "disabled": false }, "fid": "INFO_GF", "seq": null, "valid": true, "domain": "INFO", "path": ["Alle Infos", "Geschäftsführung"] }, { "name": "Qualitätsmanagement", "id": 518, "state": { "checked": false, "disabled": false }, "fid": "INFO_QM", "seq": null, "valid": true, "domain": "INFO", "path": ["Alle Infos", "Qualitätsmanagement"], "children": [{ "name": "Geräteverwaltung", "id": 521, "state": { "checked": false, "disabled": false }, "fid": "QM_I", "seq": null, "valid": true, "domain": "INFO", "path": ["Alle Infos", "Qualitätsmanagement", "Geräteverwaltung"] }, { "name": "Ringversuche", "id": 522, "state": { "checked": false, "disabled": false }, "fid": "QM_E", "seq": null, "valid": true, "domain": "INFO", "path": ["Alle Infos", "Qualitätsmanagement", "Ringversuche"] }] }] }],
    headers: [
      { text: '', value: 'is_important', align: 'left px-1', sortable: false },
      { text: 'Datum', value: 'id', align: 'left px-1', sortable: false, extSort: true, sortDir: 'desc', sortSeq: 1 },
      { text: 'Von', value: 'created_by', align: 'left px-1', sortable: false, extSort: true, sortDir: 'noSort', },
      { text: 'Nachricht', value: 'text', align: 'left px-1', sortable: false },
      { text: 'Anhang', value: 'has_uploads', align: 'left px-1', sortable: false },
      { text: 'Aktionen', align: 'center', sortable: false },
    ],
    rowsPerPageItems: [5, 10, 20, 50],
    responseData: {},
    dataCounter: 0,
    activeTreeItems: [],
    file: '',
    filter: {
      left: {
      },
      main: {
      },
      opt: {},
    },

    selected_posts_for_mass_edit: [],
    search_term: null,
    posts: [],
    categories: [],
    selected_category_id: undefined,
    selected_filter_value: null,
    request_in_progress: false,

    // assigned via :key, incrementing forces vue to rerender the component
    rerender_post_component_key: 0,
    rerender_post_form_component_key: 0,
  }),


  created() {
    let self = this;
    this.debug && console.log(`component Infoboard created...`)
    this.debug && console.log(`${this.baseUrl.replace(/\/$/, '')}${this.$route.path}`)

    this.$eventbus.$emit('close_sidebar_left')

    EventBus.$off('infoboardGrid_new')
    EventBus.$on('infoboardGrid_new', function () {
      self.show_create_form = true
    })


  },

  mounted() {
    this.$root.$refs.mainapp.$refs.appBarTitle.innerHTML = "Infoboard";

    //select active node in treeview
    this.activeTreeItems.push(this.filter.depts_id[0])
  },

  methods: {

    getActiveValue(value) {
      //console.log(value)
      this.filter.depts_id = value
      EventBus.$emit('infoboardGrid_refresh')
    },


    select_post(selected_post) {
      this.post.selected = selected_post;
      this.post.show = true;
    },

    get_posts() {
      if (this.search_term) {
        this.search();
        return;
      }
      this.request_in_progress = true;
      this.$http({
        method: "get",
        url: this.post_url(),
      })
        .then((response) => {
          this.posts = response.data.posts;
          this.responseData = response.data.posts;
          this.pagination.total_entries = response.data.total_entries;
        })
        .catch((response) => { })
        .finally(() => {
          this.request_in_progress = false;
        });
    },

    mark_as_read() {
      this.request_in_progress = true;
      this.$http({
        method: "post",
        url: `${this.$root.app_url}/infoboard/posts/mark_as_read`,
        data: { post_ids: this.selected_posts_for_mass_edit },
      })
        .then((response) => {
          this.selected_posts_for_mass_edit = [];
          EventBus.$emit('infoboardGrid_refresh')
        })
        .catch((response) => { })
        .finally(() => {
          this.request_in_progress = false;
        });
    },

    mark_all_as_read() {
      this.request_in_progress = true;
      this.$http({
        method: "post",
        url: `${this.$root.app_url}/infoboard/posts/mark_all_as_read`,
      })
        .then((response) => {

          EventBus.$emit('infoboardGrid_refresh')
          this.$eventbus.$emit('infoboard_post_marked_as_seen')
        })
        .catch((response) => { })
        .finally(() => {
          this.request_in_progress = false;
        });

    },

    emit(what, payload) {
      if (what === 'new') {
        payload = this.url + '/new'
      }
      EventBus.$emit(what, payload)
    },

    post_date_range_is_valid_for_today(post) {
      let today = new Date()
      // no date range defaults to true
      let is_valid = true
      if (post.valid_from && post.valid_till) {
        let start_date = new Date(post.valid_from)
        let end_date = new Date(post.valid_till)
        end_date.setHours(23)
        end_date.setMinutes(59)
        end_date.setSeconds(59)
        is_valid = (today >= start_date && today <= end_date)
      }

      return is_valid
    }
  },
};
</script>